import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import store from './store';

import App from './App';
import './index.css';


ReactDOM.render((
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <HashRouter>
                <Route path="*" component={App} />
            </HashRouter>
        </Provider>
    </MuiThemeProvider>
), document.getElementById('root'));