import constants from '../actions/constants';
import initialState from '../initialState';

const searchReducer = (state = initialState.search, action) => {

    // console.log(action);
    
    const newState = {...state};

    switch(action.type){
        case constants.SEARCH_STARTED:
            newState.error = '';
            newState.isFetching = true;
            return newState;
        case constants.SEARCH_COMPLETED:
            newState.error = '';
            newState.isFetching = false;
            newState.results = action.value;
            return newState;
        case constants.SEARCH_CLEAR:
            newState.error = '';
            newState.isFetching = false;
            newState.criteria.firstname = '';
            newState.criteria.lastname = '';
            newState.criteria.email = '';
            newState.criteria.cellphone = '';
            newState.results = [];
            return newState;
        case constants.SEARCH_CRITERIA:
            newState.criteria[action.value.field] = action.value.value;
            return newState;
        case constants.SEARCH_ERROR:
            newState.error = action.value.message;
            newState.isFetching = false;
            return newState;
        case constants.UPDATE_PARTICIPANT:
            newState.error = '';
            newState.isFetching = false;
            newState.results.forEach((c,cid) => {
                c.participations.forEach((p,pid) => {
                    if (p.id !== action.value.id) {
                        return;
                    }
                    newState.results[cid].participations[pid] = Object.assign({}, p, action.value);
                });
            });
            return newState;
        default:
            return state;
    }
}
export default searchReducer;