import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
    DataTypeProvider,
    EditingState,
    IntegratedPaging,
    IntegratedSelection,
    IntegratedSorting,
    PagingState,
    SelectionState,
    SortingState,
  } from '@devexpress/dx-react-grid';
import {
    Grid,
    PagingPanel,
    TableHeaderRow, 
    TableColumnResizing,
    TableEditRow,
    TableEditColumn,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import {
    Fab,
    IconButton,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { 
    Clear,
    CheckCircle,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { clearSearchResults } from '../../store/actions/search';
import { updateCheckIn } from '../../store/actions/checkin';

const styles = theme => ({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(2),
    },
});

class Results extends Component {

    startOver = () => {
        this.props.clearResults();
        this.props.history.push('/search');
    }

    /**
     * Saving inline row editing
     */

    commitChanges = ({ added, changed, deleted }) => {

        const { results } = this.props;

        // We don't allow adding
        if (added) {
            return;
        }

        if (changed) {
            // console.log('changed', changed);

            Object.keys(changed).forEach(rowId => {
                if (typeof changed[rowId] === 'undefined') {
                    // nothing changed
                    return;
                }
                const { id } = results[rowId];
                const changes = changed[rowId];
                this.props.checkIn(id, changes);
            });
        }
        
        // We don't allow deleting
        if (deleted) {
            return;
        }
    }

    /**
     * Check In Formatting
     */

    checkInFormatter = record => {
        
        const { row } = record;
        const { id, status_id } = row;
        const newStatusId = status_id===2 ? 1 : 2;

        return (
            <IconButton 
                color={status_id===2 ? "secondary" : "default"}
                onClick={() => {
                    this.props.checkIn(id, { status_id: newStatusId });
                }}
                disabled={this.props.loading}
            >
                <CheckCircle />
            </IconButton>
        );
    };

    checkInEditor = record => {
        const { value, onValueChange } = record;
        return  <Select
            value={typeof value === 'undefined' ? 1 : value}
            onChange={e => onValueChange(e.target.value)}
            fullWidth
            variant="standard"
        >   
            {[1,2].map((v,i) => <MenuItem value={v} key={v}>{parseInt(v)===2 ? 'Yes' : 'No'}</MenuItem>)}
        </Select>;
    };

    checkInProvider = props => {
        return <DataTypeProvider
            formatterComponent={this.checkInFormatter}
            editorComponent={this.checkInEditor}
            {...props}
        />;
    };

    /**
     * No Edit Formatting
     */

    noEditFormatter = record => {
        const { value } = record;
        return value;
    };

    noEditEditor = record => {
        const { value } = record;
        return value;
    };

    noEditProvider = props => {
        return <DataTypeProvider
            formatterComponent={this.noEditFormatter}
            editorComponent={this.noEditEditor}
            {...props}
        />;
    };

    /**
     * Number Edit Formatting
     */

    numberEditor = record => {
        const { value, onValueChange } = record;
        return (
            <TextField
                type="text" // Firefox is a bit weird about number fields
                value={value}
                onChange={e => {
                    const updatedValue = (e.target.value || '')
                        .replace(/\D+/g, '');
                    return onValueChange(updatedValue);
                }}
                fullWidth
            />
        );
    };

    numberProvider = props => {
        return <DataTypeProvider
            editorComponent={this.numberEditor}
            {...props}
        />;
    };

    /**
     * display changes
     */

    render() {

        const { classes } = this.props;

        const loadingStyleProps ={
            opacity: this.props.loading ? 1 : 0,
        };

        const CheckInProvider = this.checkInProvider;
        const NoEditProvider = this.noEditProvider;
        const NumberProvider = this.numberProvider;

        return (
            <div className="Results">
                <div className="loading" style={loadingStyleProps}>
                    <LinearProgress mode="indeterminate" color="secondary" />
                </div>
                <br />
                <Typography variant="h3" gutterBottom>Results</Typography>
                {this.props.error ?
                    <>
                        <br />
                        <Typography variant="h5" color="error" gutterBottom>{this.props.error}</Typography>
                    </>
                : ''}
                {this.props.results.length===0 ?
                    <Typography variant="h5" color="error" gutterBottom>-- No Results --</Typography>
                :
                    <>
                        <Paper className={classes.paper}>
                            <Grid
                                rows={this.props.results.map(p => {
                                    const { first_name, last_name } = p.contact;
                                    let liability_release = 'No';
                                    if (
                                        (
                                            p.event_id === 37 && // 2024 Youth
                                            Array.isArray(p['Models_of_Pride_2024_Registration_MOP_32.Models_of_Pride_2024_Release_of_Liability']) &&
                                            p['Models_of_Pride_2024_Registration_MOP_32.Models_of_Pride_2024_Release_of_Liability'].includes('1')
                                        ) ||
                                        (
                                            p.event_id === 38 && // 2024 PPI Liability Release
                                            Array.isArray(p['PPI_Registration_2024_MOP_32.Models_of_Pride_32_Release_of_Liability']) &&
                                            p['PPI_Registration_2024_MOP_32.Models_of_Pride_32_Release_of_Liability'].includes('1')
                                        )
                                    ) {
                                        liability_release = 'Yes';
                                    }
                                    return {
                                        ...p,
                                        first_name,
                                        last_name,
                                        email: p.contact['email_primary.email'],
                                        phone: p.contact['phone_primary.phone'],
                                        liability_release,
                                    };
                                })}
                                columns={[
                                    // { name: 'contact_id', title: 'ID' },
                                    { name: 'status_id', title: 'Checked In' },
                                    { name: 'first_name', title: 'First Name' },
                                    { name: 'last_name', title: 'Last Name' },
                                    // { name: 'email', title: 'Email' },
                                    { name: 'phone', title: 'Phone' },
                                    { name: 'event_id.title', title: 'Event Type' },
                                    { name: 'Models_of_Pride_2024_Registration_MOP_32.Emergency_Contact_Name', title: 'Emergency Contact Name' },
                                    { name: 'Models_of_Pride_2024_Registration_MOP_32.Emergency_Contact_Phone_Number', title: 'Emergency Phone Number' },
                                    { name: 'liability_release', 'title': 'Liability Release' },
                                ]}
                            >
                                <CheckInProvider
                                    for={['status_id']}
                                />
                                <NoEditProvider
                                    for={[
                                        'first_name',
                                        'last_name',
                                        'phone',
                                        'event_id.title',
                                        'liability_release',
                                    ]}
                                />
                                <NumberProvider
                                    for={[
                                        'Models_of_Pride_2024_Registration_MOP_32.Emergency_Contact_Phone_Number',
                                    ]}
                                />

                                <EditingState
                                    onCommitChanges={this.commitChanges}
                                    // columnExtensions={this.state.editingStateColumnExtensions}
                                />
                                <SortingState
                                    defaultSorting={[
                                        { columnName: 'first_name', direction: 'asc' },
                                    ]}
                                />
                                <PagingState
                                    defaultCurrentPage={0}
                                    defaultPageSize={15}
                                />
                                <SelectionState />

                                <IntegratedSorting />
                                <IntegratedPaging />
                                <IntegratedSelection />

                                <VirtualTable />
                                <TableColumnResizing 
                                    defaultColumnWidths={[
                                        // { columnName: 'contact_id', width: 75 },
                                        { columnName: 'status_id', width: 100 },
                                        { columnName: 'first_name', width: 150 },
                                        { columnName: 'last_name', width: 150 },
                                        // { columnName: 'email', width: 200 },
                                        { columnName: 'phone', width: 150 },
                                        { columnName: 'event_id.title', width: 250 },
                                        { columnName: 'Models_of_Pride_2024_Registration_MOP_32.Emergency_Contact_Name', width: 200 },
                                        { columnName: 'Models_of_Pride_2024_Registration_MOP_32.Emergency_Contact_Phone_Number', width: 150 },
                                        { columnName: 'liability_release', width: 100 },
                                    ]} 
                                />
                                <TableHeaderRow showSortingControls={true} />

                                <TableEditRow />
                                <TableEditColumn
                                    // showAddCommand
                                    showEditCommand
                                    // showDeleteCommand
                                />
                                
                                <PagingPanel 
                                    pageSizes={[ 5, 10, 15 ]} 
                                />
                            </Grid>
                        </Paper>
                    </>
                }
                <Fab 
                    color="primary"
                    className={classes.fab}
                    onClick={() => this.startOver()}
                    disabled={this.props.loading}
                >
                    <Clear />
                </Fab>
            </div> 
        );
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = state => {
    const { search, checkin } = state;

    /*
     * convert to a participations list 
     */

    const results = [];
    if (search.results.length>0) {
        search.results.forEach(c => {
            const contact = {...c};
            delete contact.participations;
            c.participations.forEach(p =>{
                // console.log(p);
                // const dietary_restriction = ('custom_101' in p ? p.custom_101 : ('custom_66' in p ? p.custom_66: null));
                // switch (dietary_restriction) {
                //     case '1': // Meat Eater
                //         p.dietary_restriction = 'R';
                //         break;
                //     case '2': // Vegan
                //         p.dietary_restriction = 'V';
                //         break;
                //     case '3': // Gluten Free
                //         p.dietary_restriction = 'GF';
                //         break;
                //     default:
                //         p.dietary_restriction = '';
                //         break;
                // }
                // add values to results
                results.push({
                    contact,
                    ...p,
                });
            });
        });
        // console.log(results);
    }

    return {
        results: results,
        error: [search.error,checkin.error].filter(e => e.length>0).join(', '),
        loading: search.isFetching || checkin.isFetching, // not sure if the best case it to repull search results or just update object
    };
}
  
const mapDispatchToProps = dispatch => {
    // console.log("App mapDispatchToProps", dispatch);
    return {
        clearResults: () => dispatch(clearSearchResults()),
        checkIn: (id, values) => dispatch(updateCheckIn(id, values)),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(Results)
);