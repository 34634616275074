import constants from './constants';
import store from '../';

// Redux action creators.
export const loginAction = (username, password) => dispatch => {
    dispatch({ type: constants.TRYING_LOGIN, });

    const auth = store.getState().auth;

    const authorization = btoa(`${username}:${password}`);

    return fetch(auth.url.replace('/civicrm/ajax/api4','') +'/civicrm/authx/login',{
        method: "POST",
        headers: {
            Authorization: 'Basic ' + authorization,
            'X-Requested-With': 'XMLHttpRequest',
        }, 
    })
    .then(async response => {
        if (response.status === 200) {
            return response.json();
        }
        const error = await response.text();
        throw new Error(error.replace(/^HTTP\s\d{3}/,''));
    })
    .then(json => {
        dispatch(loggedIn(authorization, json.contact_id));
    })
    .catch(err =>  dispatch(loginError(new Error(err ? err.message : 'There was an error'))));
};

const loggedIn = (authorization, contactId) => ({
    type: constants.LOGIN,
    value: {
        authorization,
        contactId,
    },
});

export const loginError = error => ({
    type: constants.LOGIN_ERROR,
    value: error,
});

export const logoutAction = () => dispatch => {
    dispatch({ type: constants.SEARCH_CLEAR });
    dispatch({ type: constants.LOGOUT });
};

export const toggleTestMode = bool => ({
    type: constants.TEST_MODE,
    value: !!bool,
});