import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

// Basic Login form: https://medium.com/technoetics/create-basic-login-forms-using-create-react-app-module-in-reactjs-511b9790dede
class NotFound extends Component {

    render() {

        return (
            <div className="NotFound">
                <Typography variant="h3" gutterBottom>Page Not Found</Typography>
                <Button 
                    component={Link}
                    variant="contained"
                    raised="true"
                    color="primary"
                    to="/"
                >Go Home</Button>
            </div> 
        );
    }
}
  
export default withTheme(NotFound);