import store from '../';

export const civiURLBuild = (entity, action) => {

    const auth = store.getState().auth;
    // console.log('auth', auth);

    let url = auth.url + '/' +
      encodeURIComponent(entity) + '/' +
      encodeURIComponent(action);
    return url;
}
