import constants from './actions/constants';

export const defaultState = {

    search: {
        isFetching: false,
        error: '',

        criteria: {
            // events: [],
            firstname: '',
            lastname: '',
            email: '',
            cellphone: '',
            currentEvents: true,
        },

        results: [],
    },

    checkin: {
        isFetching: false,
        error: '',
    },

    auth: {

        version: '2024-1.0',
        
        isFetching: false,
        error: '',

        testMode: false,

        authorized: false,
        url: 'https://modelsofpride.org/civicrm/ajax/api4',
        // siteKey: '73d034e730de9da89c8236e3730d4e41',
        // defaultApiKey: '6PGdFSRHhaAI0rchJOpiiaQ0', // this is the api key to very unprivliged user
        // apiKey: '',
        authorization: null, // this should be a jwt, but no support to generate dynamically currently
        contactId: null,
        
        live: {
            url: 'https://modelsofpride.org/civicrm/ajax/api4',
        },

        dev: {
            url: 'https://modelsofpride.org/civicrm/ajax/api4',
        },

    },
};

let initialState = {...defaultState};

const storedStateJSON = localStorage.getItem(constants.LOCAL_STORAGE_KEY);
if (storedStateJSON!==null) {
    try {
        const storedState = JSON.parse(storedStateJSON);

        // version; clear stored state if version changed
        if (!('version' in storedState.auth) || initialState.auth.version !== storedState.auth.version) {
            
            localStorage.removeItem(constants.LOCAL_STORAGE_KEY);

        } else {
        
            // auth
            initialState.auth.testMode = storedState.auth.testMode;
            initialState.auth.authorized = storedState.auth.authorized;
            initialState.auth.url = storedState.auth.url;
            initialState.auth.authorization = storedState.auth.authorization;
            initialState.auth.contactId = storedState.auth.contactId;
            
            // search
            // initialState.search = Object.assign({}, initialState.search, storedState.search);
            // initialState.search.isFetching = false;
            // initialState.search.error = '';

            // check in
        }
        
    } catch (e) {
        // wasn't json i guess, no worries
    }
}

// console.log('defaultState', defaultState);
// console.log('initialState', initialState);
export default initialState; 

