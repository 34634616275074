import { combineReducers } from 'redux';
import authReducer from './auth';
import searchReducer from './search';
import checkinReducer from './checkin';

const reducer = combineReducers({
    auth: authReducer,
    search: searchReducer,
    checkin: checkinReducer,
});

export default reducer;