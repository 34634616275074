import constants, { participantSelect } from './constants';
import { civiURLBuild } from './helpers';
import store from '../';

// reload load a participant
const reloadCheckIn = id => {

    const auth = store.getState().auth;

    const params = {
        select: participantSelect,
        where: [
            ['id', '=', id]
        ],
        limit: 1,
    };

    const body = new FormData();
    body.append("params", JSON.stringify(params));
    
    return fetch(
        civiURLBuild('Participant', 'get'),
        {
            method: "POST",
            headers: {
                Authorization: 'Basic ' + auth.authorization,
                'X-Requested-With': 'XMLHttpRequest',
            }, 
            body,
        }
    )
    .then(response => response.json())
    .then(json => {
        if ('error_message' in json) {
            throw Error(json.error_message);
        }
        return json.values[0];
    });
    // Leave catch for update check in
};

// Redux action creators.
export const updateCheckIn = (id, values) => dispatch => {
    dispatch({ type: constants.CHECKIN_STARTED, });

    const auth = store.getState().auth;

    const params = {
        values,
        where: [
            ['id', '=', id]
        ],
        limit: 1,
    };

    const body = new FormData();
    body.append("params", JSON.stringify(params));
    
    return fetch(
        civiURLBuild('Participant','update'),
        {
            method: "POST",
            headers: {
                Authorization: 'Basic ' + auth.authorization,
                'X-Requested-With': 'XMLHttpRequest',
            }, 
            body,
        }
    )
    .then(response => response.json())
    .then(json => {
        if ('error_message' in json) {
            throw Error(json.error_message);
        }
        return json.values[0];
    })
    .then(participant => reloadCheckIn(participant.id))
    .then(participant => {
        dispatch(checkInSuccess());
        dispatch({
            type: constants.UPDATE_PARTICIPANT,
            value: participant,
        });
    })
    .catch(e => dispatch(checkInError(e)));
};

const checkInError = error => ({
    type:   constants.CHECKIN_ERROR,
    value:  error,
});

const checkInSuccess = participant => ({
    type:   constants.CHECKIN_COMPLETED,
    value:  participant,
});