import constants from '../actions/constants';
import initialState from '../initialState';

const saveReducer = (state = initialState.search, action) => {
    
    const newState = {...state};

    switch(action.type){
        case constants.CHECKIN_STARTED:
            newState.error = '';
            newState.isFetching = true;
            return newState;
        case constants.CHECKIN_COMPLETED:
            newState.error = '';
            newState.isFetching = false;
            return newState;
        case constants.CHECKIN_ERROR:
            newState.error = action.value.message;
            newState.isFetching = false;
            return newState;
        default:
            return state;
    }
}
export default saveReducer;