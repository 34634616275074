import constants, { participantSelect } from './constants';
import { civiURLBuild } from './helpers';
import * as moment from 'moment-timezone';
import store from '../';

// Redux action creators.
export const searchAction = criteria => dispatch => {
    dispatch({ type: constants.SEARCH_STARTED });

    const { firstname, lastname, email, cellphone, currentEvents } = criteria;

    const searches = [];
    if (firstname.length > 0 || lastname.length > 0) {
        searches.push(nameSearch(firstname, lastname, currentEvents));
    }
    if (email.length > 0) {
        searches.push(emailSearch(email, firstname, lastname, currentEvents));
    }
    if (cellphone.length > 0) {
        searches.push(cellPhoneSearch(cellphone, firstname, lastname, currentEvents));
    }    

    return Promise.all(searches)
    // merge seraches
    .then(searches => [].concat.apply([], searches))
    // deduplicate results
    .then(contacts => contacts.filter((contact, i, self) => i === self.findIndex(t => t.id === contact.id)))
    .then(contacts => {
        if (contacts.length>0) {
            return dispatch(searchCompleted(contacts));
        }
        throw Error('No matching contacts');
    })
    .catch(e => dispatch(searchError(e)));
};

const nameSearch = (first_name, last_name, current_events) => {

    if (first_name.length===0 && last_name.length===0)
        return [];

    const auth = store.getState().auth;
    
    const participantWhere = [
        ['contact_id', '=', '$id']
    ];
    if (current_events) {
        const momentNow = moment().tz('America/Los_Angeles').startOf('day');
        participantWhere.push(['event_id.start_date', '>=', momentNow.format('YYYY-MM-DD HH:mm:ss')])
    }
    // participantWhere.push(['event_id', '=', 39554]);

    const params = {
        select: ['*', 'email_primary.email', 'phone_primary.phone'],
        where: [
            ['first_name', 'LIKE', "%" + first_name + "%"],
            ['last_name', 'LIKE', "%" + last_name + "%"],
        ],
        chain:
        {
            'participations': [
                'Participant', 'get', {
                    select: participantSelect,
                    where: participantWhere,
                    limit: 1000,
                },
            ],
        },
        limit: 1000,
    };
    // console.log(params);

    const body = new FormData();
    body.append("params", JSON.stringify(params));

    return fetch(
        civiURLBuild('Contact','get'),
        {
            method: "POST",
            headers: {
                Authorization: 'Basic ' + auth.authorization,
                'X-Requested-With': 'XMLHttpRequest',
            },
            body,
        }
    )
    .then(response => response.json())
    .then(json => {
        if ('error_message' in json) {
            throw Error(json.error_message);
        }
        return json.values;
    });
};

const flattenResults = results => {
    return results.map(result => {
        const { participations } = result;
        const contact = { participations };
        Object.keys(result).forEach(key => {
            if (key.startsWith('contact_id.')) {
                contact[key.replace('contact_id.', '')] = result[key];
            }
        });
        return contact;
    });
};

const emailSearch = (email, first_name, last_name, current_events) => {

    if (email.length===0) {
        return [];
    }

    const auth = store.getState().auth;

    const participantWhere = [
        ['contact_id', '=', '$contact_id']
    ];
    if (current_events) {
        const momentNow = moment().tz('America/Los_Angeles').startOf('day');
        participantWhere.push(['event_id.start_date', '>=', momentNow.format('YYYY-MM-DD HH:mm:ss')])
    }
    // participantWhere.push(['event_id', '=', 39554]);

    const params = {
        select: ['*', 'contact_id.*', 'contact_id.email_primary.email', 'contact_id.phone_primary.phone'],
        where: [
            ['email', 'LIKE', "%" + email + "%"],
            ['contact_id.first_name', 'LIKE', "%" + first_name + "%"],
            ['contact_id.last_name', 'LIKE', "%" + last_name + "%"],
        ],
        chain: {
            'participations': [
                'Participant', 'get', {
                    select: participantSelect,
                    where: participantWhere,
                    limit: 1000,
                },
            ],
        },
        limit: 1000,
    };
    // console.log(params);

    const body = new FormData();
    body.append("params", JSON.stringify(params));

    return fetch(
        civiURLBuild('Email','get'),
        {
            method: "POST",
            headers: {
                Authorization: 'Basic ' + auth.authorization,
                'X-Requested-With': 'XMLHttpRequest',
            },
            body,
        }
    )
    .then(response => response.json())
    .then(json => {
        if ('error_message' in json) {
            throw Error(json.error_message);
        }
        return json.values;
    })
    .then(emails => flattenResults(emails))
    .then(searches => [].concat.apply([], searches))
    .then(contacts => contacts.filter((contact, i, self) => i === self.findIndex(t => t.id === contact.id)))
};

const cellPhoneSearch = (cellphone, first_name, last_name, current_events) => {

    const phone_numeric = cellphone.replace(/\D/g,'');

    if (phone_numeric.length===0) {
        return [];
    }

    const auth = store.getState().auth;

    const participantWhere = [
        ['contact_id', '=', '$contact_id']
    ];
    if (current_events) {
        const momentNow = moment().tz('America/Los_Angeles').startOf('day');
        participantWhere.push(['event_id.start_date', '>=', momentNow.format('YYYY-MM-DD HH:mm:ss')])
    }
    // participantWhere.push(['event_id', '=', 39554]);

    const params = {
        select: ['*', 'contact_id.*', 'contact_id.email_primary.email', 'contact_id.phone_primary.phone'],
        where: [
            ['phone_numeric', 'LIKE', "%" + phone_numeric + "%"],
            ['contact_id.first_name', 'LIKE', "%" + first_name + "%"],
            ['contact_id.last_name', 'LIKE', "%" + last_name + "%"],
            // phone_type_id: "Mobile",
        ],
        chain: {
            'participations': [
                'Participant', 'get', {
                    select: participantSelect,
                    where: participantWhere,
                    limit: 1000,
                },
            ],
        },
        limit: 1000,
    };
    // console.log(params);

    const body = new FormData();
    body.append("params", JSON.stringify(params));

    return fetch(
        civiURLBuild('Phone','get'),
        {
            method: "POST",
            headers: {
                Authorization: 'Basic ' + auth.authorization,
                'X-Requested-With': 'XMLHttpRequest',
            },
            body,
        }
    )
    .then(response => response.json())
    .then(json => {
        if ('error_message' in json) {
            throw Error(json.error_message);
        }
        return json.values;
    })
    .then(phones => flattenResults(phones))
    .then(searches => [].concat.apply([], searches))
    .then(contacts => contacts.filter((contact, i, self) => i === self.findIndex(t => t.id === contact.id)))
};  

const searchCompleted = results => ({
    type: constants.SEARCH_COMPLETED,
    value: results,
});

export const setSearchCriteria = (field, value) => ({
    type: constants.SEARCH_CRITERIA,
    value: {field, value},
});

export const clearSearchResults = () => ({
    type: constants.SEARCH_CLEAR,
});

export const searchError = error => ({
    type: constants.SEARCH_ERROR,
    value: error,
});