import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, LinearProgress, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { loginAction, loginError } from '../../store/actions/auth';

// Basic Login form: https://medium.com/technoetics/create-basic-login-forms-using-create-react-app-module-in-reactjs-511b9790dede
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    keyPress = e => {
        if(e.keyCode === 13){
            // alert("Enter hit");
           this.login(this.state.username, this.state.password);
        }
    }

    login = () => {
        if (this.state.username==='' || this.state.password==='') {
            this.props.loginError(new Error('Username and/or password cannot be blank'));
            return;
        }
        this.props.login(this.state.username, this.state.password);
    }
  
    render() {

        const style = {
            margin: 15,
        };

        const loadingStyleProps ={
            opacity: this.props.loading ? 1 : 0,
        };

        return (
            <div className="Login">
                <div className="loading" style={loadingStyleProps}>
                    <LinearProgress mode="indeterminate" color="secondary" />
                </div>
                <br />
                <Typography variant="h3" gutterBottom>Login</Typography>
                {this.props.error ?
                    <Typography variant="h5" color="error" gutterBottom>{this.props.error}</Typography>
                : ''}
                <TextField
                    label="Username"
                    onChange = {e => this.setState({username: e.target.value})}
                    onKeyDown={this.keyPress}
                    disabled={this.props.loading}
                    required
                />
                <br/>
                <TextField
                    type="password"
                    label="Password"
                    onChange = {e => this.setState({password: e.target.value})}
                    onKeyDown={this.keyPress}
                    disabled={this.props.loading}
                    required
                />
                <br/>
                <Button 
                    variant="contained"
                    raised="true"
                    color="primary"
                    style={style} 
                    onClick={() => this.login(this.state.username, this.state.password)}
                    disabled={this.props.loading}
                >Submit</Button>
            </div> 
        );
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = state => {
    return {
        error: state.auth.error,
        loading: state.auth.isFetching,
    };
}
  
const mapDispatchToProps = dispatch => {
    // console.log("App mapDispatchToProps", dispatch);
    return {
        login: (username, password) => dispatch(loginAction(username, password)),
        loginError: (err) => dispatch(loginError(err)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTheme(Login)
);