import constants from '../actions/constants';
import initialState from '../initialState';

const authReducer = (state = initialState.auth, action) => {
    
    const newState = {...state};
    // console.log(action);

    switch(action.type){
        case constants.TEST_MODE:
            newState.testMode = action.value;
            newState.authorized = false;
            newState.authorization = null;
            newState.contactId = null;
            const mergedState = Object.assign(newState, newState.testMode ? newState.dev : newState.live);
            // console.log(newState, mergedState, newState.testMode, newState.dev, newState.live);
            return mergedState;
        case constants.LOGIN:
            newState.error = '';
            newState.isFetching = false;
            newState.authorization = action.value.authorization
            newState.contactId = action.value.contactId;
            newState.authorized = true;
            return newState;
        case constants.TRYING_LOGIN:
            newState.error = '';
            newState.isFetching = true;
            return newState;
        case constants.LOGIN_ERROR:
            newState.error = action.value.message;
            newState.isFetching = false;
            return newState;
        case constants.LOGOUT:
            newState.authorization = null;
            newState.contactId = null;
            newState.authorized = false;
            return newState;
        default:
            return state;
    }
}
export default authReducer;