import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#9239cb',
        },
        secondary: {
            main: '#25bdad',
            contrastText: '#fff',
        },
        text: {
            primary: 'rgb(51, 51, 51)',
            secondary: 'rgb(51, 51, 51)',
        },
    },
    typography: {
        fontFamily: [
            '"Noticia Text"',
            'serif',
        ].join(','),
        display4: {
            fontFamily: [
                '"Teko"',
                "sans-serif",
            ].join(','),
            color: '#fff',
        },
        h2: {
            fontFamily: [
                '"Teko"',
                "sans-serif",
            ].join(','),
            color: '#fff',
        },
        h3: {
            fontFamily: [
                '"Teko"',
                "sans-serif",
            ].join(','),
        },
        display1: {
            fontFamily: [
                '"Teko"',
                "sans-serif",
            ].join(','),
        },
        h5: {
            fontFamily: [
                '"Teko"',
                "sans-serif",
            ].join(','),
        },
        title: {
            fontFamily: [
                '"Teko"',
                "sans-serif",
            ].join(','),
        },
    },
});

export default theme;